import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="darmsanierung" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Stuhl-Labor`}</h1>
      <h3 {...{
        "id": "stuhl-labor"
      }}>{`Stuhl-Labor`}</h3>
      <p>{`"Der Tod steckt im Darm“`}</p>
      <p>{`Diese alte Volksweisheit erfährt heute ihre wissenschaftliche Begründung durch die moderne Immunologie. Denn der Darm ist nicht nur Verdauungsorgan - das sogenannte „darmassoziierte Immunsystem“ kann verantwortlich sein für Blähungen, Durchfall und Verstopfung, aber auch für Allergien, Juckreiz, Ekzeme, Neurodermitis und eine Vielzahl chronischer Beschwerden wie Infektanfälligkeit, Migräne und depressive Verstimmungen. Zur ursachenorientierten Behandlung bedarf es einer vorausgehenden, aussagekräftigen Analyse der Darmverhältnisse per Stuhluntersuchung.`}</p>
      <h2 {...{
        "id": "die-moderne-stuhlanalytik-klart-folgende-fragestellungen"
      }}>{`Die moderne Stuhlanalytik klärt folgende Fragestellungen:`}</h2>
      <ul>
        <li parentName="ul">{`liegt ein Befall mit Hefe- u/o Schimmelpilzen vor, die das Immunsystem schwächen?`}</li>
        <li parentName="ul">{`sind die richtigen Bakterien, die uns am Leben erhalten, im richtigen Mengenverhältnis vorhanden?`}</li>
        <li parentName="ul">{`liegen prinzipiell eher Lebensmittel-Allergien oder –Unverträglichkeiten vor?`}</li>
        <li parentName="ul">{`stimmt das Säure-Milieu (pH-Wert)?`}</li>
        <li parentName="ul">{`liegt ein Parasitenbefall vor (evtl. durch Haustiere, nach Fernreisen)?`}</li>
        <li parentName="ul">{`produzieren Leber und Bauchspeicheldrüse ausreichend Enzyme um die Nahrung aufzuschließen?`}</li>
        <li parentName="ul">{`finden sich unverdaute Nahrungsreste, die zu Gärung, Fäulnis und Leberbelastung führen?`}</li>
        <li parentName="ul">{`gibt es Blutungen, die einer weiteren Abklärung bedürfen?`}</li>
        <li parentName="ul">{`gibt es Entzündungen, Geschwüre, Polypen, Tumore?`}</li>
        <li parentName="ul">{`liegt ein Befall des Magens mit Helicobacter pylori vor, der zu Geschwüren führen kann?`}</li>
      </ul>
      <p>{`Aus diesen zahlreichen Möglichkeiten wählen wir die entsprechend sinnvollen Bestimmungen/Kombinationen aus und können so je nach Befundung die gezielte `}<a parentName="p" {...{
          "href": "/therapien/darmsanierung"
        }}>{`Darmsanierung`}</a>{` einleiten.`}</p>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      